import type { DoraReportingCategory } from '@assembly-web/services';
import { useUserDetails } from '@assembly-web/services';
import { defineMessages, type MessageDescriptor, useIntl } from 'react-intl';

import { useChatReportHealth } from './useChatReportHealth';

const messages = defineMessages({
  availableDataLabel: {
    defaultMessage: 'Available data',
    id: 'voV6qi',
  },
  availableDataDescription: {
    defaultMessage: 'What data do you have access to?',
    id: 'D+VbEP',
  },
  doraAiRecommendationsLabel: {
    defaultMessage: 'DoraAI recommendations',
    id: 'cJtdnJ',
  },
  doraAiRecommendationsDescription: {
    defaultMessage:
      'What interesting trends or recommendations do you have for charts?',
    id: '0DuFMN',
  },
  recognitionOverTimeLabel: {
    defaultMessage: 'Recognition sent over time',
    id: 'aZI7oK',
  },
  recognitionOverTimeDescription: {
    defaultMessage:
      'Generate a chart of recognition posts sent over time for the past 6 months.',
    id: 'fm8Pz5',
  },
  coreValuesRecognizedLabel: {
    defaultMessage: 'Core values recognized',
    id: 'ZDNzIW',
  },
  coreValuesRecognizedDescription: {
    defaultMessage:
      'Generate a pie chart of the most common core values used in recognitions sent in the past 3 months.',
    id: 'Xh4NeD',
  },
  usersNotBeingRecognizedLabel: {
    defaultMessage: 'Users not being recognized',
    id: '97vW9g',
  },
  usersNotBeingRecognizedDescription: {
    defaultMessage:
      'Which active users have not been recognized in the past 3 months?',
    id: 'CqiRQY',
  },
  unusedAllowanceLabel: {
    defaultMessage: 'Unused allowance',
    id: 'SsOSVD',
  },
  unusedAllowanceDescription: {
    defaultMessage: 'Which active users had left over allowances last month?',
    id: 'Ep3fAB',
  },
  usersNotPostingInFlowsLabel: {
    defaultMessage: 'Users not posting in flows',
    id: 'q/1SxX',
  },
  usersNotPostingInFlowsDescription: {
    defaultMessage:
      'Which active users haven’t posted in a flow in the past 3 months?',
    id: 'DyrOEw',
  },
  flowsWithLowParticipationLabel: {
    defaultMessage: 'Flows with low participation',
    id: 'tVQMlk',
  },
  flowsWithLowParticipationDescription: {
    defaultMessage:
      'Which active flows have had no activity in the past 3 months',
    id: 'TU5Lm4',
  },
  employeeTenuresLabel: {
    defaultMessage: 'Employee tenures',
    id: 'U/1lZQ',
  },
  employeeTenuresDescription: {
    defaultMessage:
      'Generate a pie chart displaying how long employees have worked here. Group them into the following categories: Less than 1 year, 1-3 years, 3-5 years, 5-10 years, More than 10 years',
    id: 'Z24Mez',
  },
  employeeGroupBreakdownLabel: {
    defaultMessage: 'Employee group breakdown',
    id: 'j0Fesq',
  },
  employeeGroupBreakdownDescription: {
    defaultMessage:
      'Give me a CSV with a breakdown of all the different departments, job titles, and locations for active employees at the company. Also include how many active users belong in each department/job title/location. Columns should include Departments, Department Count, Job Titles, Job Title User Count, Locations, Location User Count',
    id: 'WAts+B',
  },
  topRecognitionRecipientsLabel: {
    defaultMessage: 'Top recognition recipients',
    id: 'yAnRz2',
  },
  topRecognitionRecipientsDescription: {
    defaultMessage:
      'Please create a table listing the top 10 recognition recipients by the number of recognition posts received and the total {points} awarded to them, sorted by posts, for the last 3 months.',
    id: 'BqFYuU',
  },
  crossDepartmentalRecognitionLabel: {
    defaultMessage: 'Cross-departmental recognition',
    id: 'ab4fpm',
  },
  crossDepartmentalRecognitionDescription: {
    defaultMessage:
      'Create a heat map that visualizes the frequency of recognition posts between departments over the last 3 months. Identify the top 10 most active departments on the platform based on their total recognition activity (both sending and receiving). Use these departments for both axes of the heat map, with one axis representing the sending department and the other the receiving department. Fill the heat map with the frequency of recognition posts exchanged between these departments.',
    id: 'VPGN21',
  },
  recognitionReceivedByDepartmentLabel: {
    defaultMessage: 'Recognition received by department',
    id: 'TEN6Yo',
  },
  recognitionReceivedByDepartmentDescription: {
    defaultMessage:
      'Create a dual-axis bar chart displaying the total number of recognition posts and {points} received by each department in the last 3 months, sorted in descending order. Show only the top 10 departments.',
    id: 'AeRs9/',
  },
  recognitionReceivedByLocationLabel: {
    defaultMessage: 'Recognition received by location',
    id: 'tzUVJt',
  },
  recognitionReceivedByLocationDescription: {
    defaultMessage:
      'Produce a dual-axis bar chart that compares the total recognition posts and {points} received across different locations in the last 3 months, with locations sorted by the number of posts. Show only the top 10 locations',
    id: 'Y5zylk',
  },
  recognitionReceivedByTeamLabel: {
    defaultMessage: 'Recognition received by team',
    id: '9Znsyd',
  },
  recognitionReceivedByTeamDescription: {
    defaultMessage:
      "Generate a dual-axis bar chart displaying the number of recognition posts and the total recognition {points} received by each team in the last 3 months. Teams should be organized by their manager's name and arranged in descending order based on the number of posts received. Show only the top 10 teams.",
    id: 'CuIiRy',
  },
  teamRecognitionReceivedLabel: {
    defaultMessage: 'Team recognition received',
    id: 'f6D9kU',
  },
  teamRecognitionReceivedDescription: {
    defaultMessage:
      'Create a bar chart of recognition received by my team in the last 3 months, detailing each member’s total recognition posts received, sorted by posts received.',
    id: 'MkAbix',
  },
  whoNeedsRecognitionLabel: {
    defaultMessage: 'Who needs recognition?',
    id: 'bTy8Wi',
  },
  whoNeedsRecognitionDescription: {
    defaultMessage:
      'Help me identify team members who have not received recognition recently, listing Name, Date Last Recognized, and Days Since Last Recognized, sorted by the longest duration without recognition.',
    id: 'G/cdPK',
  },
  whoIsMyTeamRecognizingLabel: {
    defaultMessage: 'Who is my team recognizing?',
    id: 'FM/2Fk',
  },
  whoIsMyTeamRecognizingDescription: {
    defaultMessage:
      'Show a breakdown of the departments my team has recognized the most in terms of posts, visualized in a bar chart, sorted by the number of recognitions.',
    id: 'cCsjda',
  },
  overviewOfFlowsLabel: {
    defaultMessage: 'Overview of flows',
    id: 'c44iUp',
  },
  overviewOfFlowsDescription: {
    defaultMessage:
      'List all flows within the workspace, including Flow Name, Flow Owner, Creation Date, and Number of Posts since creation, sorted by Creation Date. Please provide a downloadable CSV.',
    id: 'VIxvdW',
  },
  topPerformingFlowsLabel: {
    defaultMessage: 'Top performing flows',
    id: 'tQW547',
  },
  topPerformingFlowsDescription: {
    defaultMessage:
      'Generate a table of the top 10 flows ranked by activity in the last 3 months, including Flow Name, Flow Creator, Creation Date, number of Posts, Comments, and Reactions since creation.',
    id: '5gKSHy',
  },
  flowEngagementByDepartmentLabel: {
    defaultMessage: 'Flow engagement by department',
    id: 'q0+5ZP',
  },
  flowEngagementByDepartmentDescription: {
    defaultMessage:
      'Create a bar chart showing the number of posts created by each department, with departments sorted by the number of posts. Show only the top 10 departments.',
    id: 'LcpG61',
  },
  flowEngagementByTeamLabel: {
    defaultMessage: 'Flow engagement by team',
    id: 'oHByMy',
  },
  flowEngagementByTeamDescription: {
    defaultMessage:
      "Display a bar chart comparing the total number of posts created by team. Teams should be organized by their manager's name, with teams ranked by their posting activity. Show only the top 10 teams.",
    id: 'A1IIx+',
  },
  flowEngagementByEmployeeLabel: {
    defaultMessage: 'Flow engagement by employee',
    id: 'eg2Sh6',
  },
  flowEngagementByEmployeeDescription: {
    defaultMessage:
      'Produce a ranked list of the top 20 most active employees in flows, sorted by posts created in the last 3 months. Include a count of comments and reactions. Please show the full table, all 20 employees.',
    id: 'r4Z6w9',
  },
  overviewOfMyFlowsLabel: {
    defaultMessage: 'Overview of my flows',
    id: '72GM+V',
  },
  overviewOfMyFlowsDescription: {
    defaultMessage:
      'List all flows owned by me, including Flow Name, Creation Date, and Number of Posts since creation, sorted by Creation Date.',
    id: 'AtdLJn',
  },
  flowEngagementByTeamMemberLabel: {
    defaultMessage: 'Flow engagement by team member',
    id: '2Zo+OV',
  },
  flowEngagementByTeamMemberDescription: {
    defaultMessage:
      'Generate a ranked list of my team members based on their engagement in flows I own, detailing posts made, sorted by the number of posts.',
    id: 'l+O9Od',
  },
  upcomingAnniversariesLabel: {
    defaultMessage: 'Upcoming anniversaries',
    id: 'OmjhK7',
  },
  upcomingAnniversariesDescription: {
    defaultMessage:
      "List all of my team members' anniversaries happening in the next 30 days, including Name, Department, and Date of Anniversary, sorted by date.",
    id: 'NvPix9',
  },
  allUpcomingAnniversariesDescription: {
    defaultMessage:
      'List all employee anniversaries happening in the next 30 days, including Name, Department, and Date of Anniversary, sorted by date.',
    id: '6XuT+u',
  },
  upcomingBirthdaysLabel: {
    defaultMessage: 'Upcoming birthdays',
    id: 'bjgIFc',
  },
  upcomingBirthdaysDescription: {
    defaultMessage:
      'Generate a list of my team members with birthdays in the next 30 days, showing Employee Name, Department, and Birthday, ordered by date.',
    id: '4JqgfG',
  },
  allUpcomingBirthdaysDescription: {
    defaultMessage:
      'Generate a list of employees with birthdays in the next 30 days, showing Employee Name, Department, and Birthday, ordered by date.',
    id: 'STjQsW',
  },
  redemptionsOverTimeLabel: {
    defaultMessage: 'Redemptions over time',
    id: 'xlHWjm',
  },
  redemptionsOverTimeDescription: {
    defaultMessage:
      'Create a line graph showing the total number of {points} redeemed for rewards each month for the last 12 months.',
    id: 'dUkiRw',
  },
  mostPopularRewardTypesLabel: {
    defaultMessage: 'Most popular reward types',
    id: 'd4JeiE',
  },
  mostPopularRewardTypesDescription: {
    defaultMessage:
      'Generate a bar chart that compares the total number of times each reward type has been redeemed.',
    id: 'N8N7tu',
  },
  redemptionsByDepartmentLabel: {
    defaultMessage: 'Redemptions by department',
    id: 'Rf7+y7',
  },
  redemptionsByDepartmentDescription: {
    defaultMessage:
      'Create a stacked bar chart displaying the count of redemptions by department over the last year. Each bar should show the count of redemptions for each reward type.',
    id: 'SQT4/e',
  },
  unusedBalancesLabel: {
    defaultMessage: 'Unused balances',
    id: 'BLP5kC',
  },
  unusedBalancesDescription: {
    defaultMessage:
      'Create a bar chart that shows the total of current point balances, or {points} not yet redeemed for rewards, by department.',
    id: 'tdck5e',
  },
  whoRespondedLabel: {
    defaultMessage: 'Who responded?',
    id: 'nHNSTo',
  },
  whoRespondedDescription: {
    defaultMessage: 'List all the people who have ever responded to this flow.',
    id: '1ygPyW',
  },
  suggestedAnalysisLabel: {
    defaultMessage: 'Suggested analysis',
    id: '1qSMHb',
  },
  suggestedAnalysisDescription: {
    defaultMessage:
      'With the flow response data you have access to, what analyses would you recommend? Include both quantitative and qualitative possibilities.',
    id: '3REH3h',
  },
});

type SuggestedReportingQuestion = {
  role: 'admin' | 'manager';
  category?: DoraReportingCategory;
  question: string;
  text: string;
};

export function useSuggestedChatQuestions(
  reportingCategory: DoraReportingCategory
): SuggestedReportingQuestion[] {
  const { formatMessage } = useIntl();

  const { data: userDetails } = useUserDetails();

  const { data: chatReportHealth } = useChatReportHealth({ enabled: true });

  const points = userDetails?.assembly.currency.pluralName ?? 'points';

  const t = (text: MessageDescriptor) => formatMessage(text, { points });

  // Define most popular questions first
  let suggestedQuestions: SuggestedReportingQuestion[] = [
    {
      role: 'admin',
      question: t(messages.availableDataLabel),
      text: t(messages.availableDataDescription),
    },
  ];

  if (reportingCategory !== 'flow_responses') {
    suggestedQuestions.push({
      role: 'admin',
      question: t(messages.doraAiRecommendationsLabel),
      text: t(messages.doraAiRecommendationsDescription),
    });
  }

  suggestedQuestions = suggestedQuestions.concat([
    {
      role: 'admin',
      category: 'recognition',
      question: t(messages.topRecognitionRecipientsLabel),
      text: t(messages.topRecognitionRecipientsDescription),
    },
    {
      role: 'admin',
      category: 'flows',
      question: t(messages.overviewOfFlowsLabel),
      text: t(messages.overviewOfFlowsDescription),
    },
    {
      role: 'admin',
      category: 'flows',
      question: t(messages.topPerformingFlowsLabel),
      text: t(messages.topPerformingFlowsDescription),
    },
    {
      role: 'admin',
      category: 'flows',
      question: t(messages.flowEngagementByEmployeeLabel),
      text: t(messages.flowEngagementByEmployeeDescription),
    },
    {
      role: 'manager',
      category: 'flows',
      question: t(messages.overviewOfMyFlowsLabel),
      text: t(messages.overviewOfMyFlowsDescription),
    },
    {
      role: 'manager',
      category: 'flows',
      question: t(messages.flowEngagementByTeamMemberLabel),
      text: t(messages.flowEngagementByTeamMemberDescription),
    },

    {
      role: 'admin',
      category: 'users',
      question: t(messages.employeeGroupBreakdownLabel),
      text: t(messages.employeeGroupBreakdownDescription),
    },
    {
      role: 'admin',
      category: 'reward_redemption',
      question: t(messages.redemptionsOverTimeLabel),
      text: t(messages.redemptionsOverTimeDescription),
    },
    {
      role: 'admin',
      category: 'reward_redemption',
      question: t(messages.mostPopularRewardTypesLabel),
      text: t(messages.mostPopularRewardTypesDescription),
    },
    {
      role: 'admin',
      category: 'reward_redemption',
      question: t(messages.unusedBalancesLabel),
      text: t(messages.unusedBalancesDescription),
    },
    {
      role: 'admin',
      category: 'flow_responses',
      question: t(messages.whoRespondedLabel),
      text: t(messages.whoRespondedDescription),
    },
    {
      role: 'admin',
      category: 'flow_responses',
      question: t(messages.suggestedAnalysisLabel),
      text: t(messages.suggestedAnalysisDescription),
    },
  ]);

  if (reportingCategory === 'flow_responses') {
    return suggestedQuestions;
  }

  //Add popular HRIS data dependent questions if available
  if (
    chatReportHealth &&
    chatReportHealth.missingDepartment / chatReportHealth.userCount < 0.2
  ) {
    suggestedQuestions.push(
      {
        role: 'admin',
        category: 'recognition',
        question: t(messages.crossDepartmentalRecognitionLabel),
        text: t(messages.crossDepartmentalRecognitionDescription),
      },
      {
        role: 'admin',
        category: 'recognition',
        question: t(messages.recognitionReceivedByDepartmentLabel),
        text: t(messages.recognitionReceivedByDepartmentDescription),
      },
      {
        role: 'manager',
        category: 'recognition',
        question: t(messages.whoIsMyTeamRecognizingLabel),
        text: t(messages.whoIsMyTeamRecognizingDescription),
      },
      {
        role: 'admin',
        category: 'flows',
        question: t(messages.flowEngagementByDepartmentLabel),
        text: t(messages.flowEngagementByDepartmentDescription),
      },
      {
        role: 'admin',
        category: 'reward_redemption',
        question: t(messages.redemptionsByDepartmentLabel),
        text: t(messages.redemptionsByDepartmentDescription),
      }
    );
  }

  if (
    chatReportHealth &&
    chatReportHealth.missingLocation / chatReportHealth.userCount < 0.2
  ) {
    suggestedQuestions.push({
      role: 'admin',
      category: 'recognition',
      question: t(messages.recognitionReceivedByLocationLabel),
      text: t(messages.recognitionReceivedByLocationDescription),
    });
  }

  if (
    chatReportHealth &&
    chatReportHealth.managersWithNoReports / chatReportHealth.userCount < 0.3
  ) {
    suggestedQuestions.push(
      {
        role: 'admin',
        category: 'recognition',
        question: t(messages.recognitionReceivedByTeamLabel),
        text: t(messages.recognitionReceivedByTeamDescription),
      },
      {
        role: 'manager',
        category: 'recognition',
        question: t(messages.teamRecognitionReceivedLabel),
        text: t(messages.teamRecognitionReceivedDescription),
      },
      {
        role: 'admin',
        category: 'flows',
        question: t(messages.flowEngagementByTeamLabel),
        text: t(messages.flowEngagementByTeamDescription),
      }
    );
  }

  if (
    chatReportHealth &&
    chatReportHealth.missingHireDate / chatReportHealth.userCount < 0.2
  ) {
    suggestedQuestions.push(
      {
        role: 'admin',
        category: 'users',
        question: t(messages.upcomingAnniversariesLabel),
        text: t(messages.allUpcomingAnniversariesDescription),
      },
      {
        role: 'admin',
        category: 'users',
        question: t(messages.employeeTenuresLabel),
        text: t(messages.employeeTenuresDescription),
      },
      {
        role: 'manager',
        category: 'users',
        question: t(messages.upcomingAnniversariesLabel),
        text: t(messages.upcomingAnniversariesDescription),
      }
    );
  }

  if (
    chatReportHealth &&
    chatReportHealth.missingBirthday / chatReportHealth.userCount < 0.2
  ) {
    suggestedQuestions.push(
      {
        role: 'admin',
        category: 'users',
        question: t(messages.upcomingBirthdaysLabel),
        text: t(messages.allUpcomingBirthdaysDescription),
      },
      {
        role: 'manager',
        category: 'users',
        question: t(messages.upcomingBirthdaysLabel),
        text: t(messages.upcomingBirthdaysDescription),
      }
    );
  }

  // Add extra questions in case HRIS dependent questions aren't available
  suggestedQuestions.push(
    {
      role: 'admin',
      category: 'recognition',
      question: t(messages.recognitionOverTimeLabel),
      text: t(messages.recognitionOverTimeDescription),
    },
    {
      role: 'admin',
      category: 'recognition',
      question: t(messages.coreValuesRecognizedLabel),
      text: t(messages.coreValuesRecognizedDescription),
    },
    {
      role: 'admin',
      category: 'recognition',
      question: t(messages.usersNotBeingRecognizedLabel),
      text: t(messages.usersNotBeingRecognizedDescription),
    },
    {
      role: 'admin',
      category: 'recognition',
      question: t(messages.unusedAllowanceLabel),
      text: t(messages.unusedAllowanceDescription),
    },
    {
      role: 'manager',
      category: 'recognition',
      question: t(messages.whoNeedsRecognitionLabel),
      text: t(messages.whoNeedsRecognitionDescription),
    },
    {
      role: 'admin',
      category: 'flows',
      question: t(messages.usersNotPostingInFlowsLabel),
      text: t(messages.usersNotPostingInFlowsDescription),
    },
    {
      role: 'admin',
      category: 'flows',
      question: t(messages.flowsWithLowParticipationLabel),
      text: t(messages.flowsWithLowParticipationDescription),
    }
  );
  return suggestedQuestions;
}
